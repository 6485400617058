// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/
:root {
  --ion-font-family: 'Montserrat';

  --ion-color-primary: #671e75;
  --ion-color-primary-rgb: 103, 30, 117;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5b1a67;
  --ion-color-primary-tint: #763583;

  --ion-color-secondary: #0768d0;
  --ion-color-secondary-rgb: 7, 104, 208;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #065cb7;
  --ion-color-secondary-tint: #2077d5;

  --ion-color-tertiary: #9757a6;
  --ion-color-tertiary-rgb: 151, 87, 166;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #854d92;
  --ion-color-tertiary-tint: #a168af;

  --ion-color-success: #198754;
  --ion-color-success-rgb: 25, 135, 84;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #16774a;
  --ion-color-success-tint: #309365;

  --ion-color-warning: #ffca2b;
  --ion-color-warning-rgb: 255, 202, 43;
  --ion-color-warning-contrast: #4A3803;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0b226;
  --ion-color-warning-tint: #ffcf40;

  --ion-color-danger: #A70F1E;
  --ion-color-danger-rgb: 167, 15, 30;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #930d1a;
  --ion-color-danger-tint: #b02735;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-medium: #53565a;
  --ion-color-medium-rgb: 83, 86, 90;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #494c4f;
  --ion-color-medium-tint: #64676b;

  --ion-color-dark: #3b4752;
  --ion-color-dark-rgb: 59, 71, 82;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #343e48;
  --ion-color-dark-tint: #4f5963;

}

// Dark mode colors
@media (prefers-color-scheme: dark) {
  :root {
    
    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

  }
}
