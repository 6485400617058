/* Estilos modificados para los componentes de Ionic */

ion-icon {
  pointer-events: none;
}

h1 {
  font-weight: 400 !important;
}

p,
h6 {
  font-weight: 500 !important;
}

ion-button {
  --box-shadow: none;
}

.ion-thumbnail-sm {
  --size: 36px;
  --border-radius: 8px;
}

.ion-thumbnail-md {
  --size: 80px;
  --border-radius: 8px;
}

.ion-thumbnail-lg {
  --size: 120px;
  --border-radius: 8px;
}

.ion-thumbnail-xl {
  --size: 180px;
  --border-radius: 8px;
}

ion-card {
  border-radius: 8px;
  box-shadow: none !important;
  border: 1px #dedede solid !important;
}

ion-datetime {
  padding: 8px;
}

ion-header {
  box-shadow: none !important;
  border-bottom: 1px solid var(--ion-color-light);
  --border-color: ;
}


.popover {
  background-color: transparent;
  display: contents;
}

ion-popover {
  ion-backdrop {
    opacity: 0.1 !important;
  }

  &::part(content) {
    width: auto;
    cursor: pointer;
  }
}

ion-select, ion-input {

  font-weight: 600;
}


//ion-select[fill="outline"] {
//  &::part(label) {
//    font-size: 1em;
//    font-weight: 600;
//  }
//}

ion-list-header ion-label h2 {
  font-weight: bold !important;
  font-size: 1em;
}

ion-select {
  color: rgba(var(--ion-color-darks-rgb), 1) !important;
}

ion-list-header i {
  color: var(--ion-color-secondary);
  float: right;
  font-size: 1.4rem;
}

ion-item {
  --border-color: rgb(233, 230, 230);
}

.ion-segment-pay {
  --background: var(--ion-color-light);
}

.ion-segment-button-pay {
  --color-checked: var(--ion-color-light);
  --background-checked: var(--ion-color-primary);
}

.ion-segment-resolver {
  --background: var(--ion-color-secondary);
}

.ion-segment-button-resolver {
  --border-radius: 3px;
  font-size: .7rem;
  --color: var(--ion-color-light);
}

@media (prefers-color-scheme: dark) {
  .ion-segment-pay {
    --background: var(--ion-color-medium-tint);
  }

  .ion-segment-button-pay {
    --color-checked: var(--ion-color-light-contrast);
    --background-checked: var(--ion-color-dark);
    --indicator-color: var(--ion-color-secondary);
  }
  ion-card {

    box-shadow: none !important;
    border: 1px var(--ion-color-light) solid !important;
  }

}


ion-list-header ion-label {
  font-weight: bold;
  opacity: 0.9;
}

ion-list-header i {
  color: var(--ion-color-secondary);
  float: right;
  font-size: 1.4rem;
}

.sc-ion-label-md-h,
.sc-ion-label-md-s {
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word;
}

ion-input:hover {
  outline: none !important;
  outline-offset: 0 !important;
}

ion-input:not(:focus) {
  outline: none !important;
  outline-offset: 0 !important;
}

ion-input.has-focus {
  outline: none !important;
  outline-offset: 0 !important;
}

ion-input:not(:focus) {
  outline: none !important;
  outline-offset: 0 !important;
}

input[type='text']:focus {
  outline: none !important;
  outline-offset: 0 !important;
}
