*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(var(--ion-color-primary-rgb), 0.75);
}


.ion-padding-content {
  padding: 20px 40px 20px 40px
}

.cursor-pointer {
  cursor: pointer;

}

.ion-cart-header {
  background-image: linear-gradient(to right, #0768d0 0%, #671e75 100%);
  transition: 0.5s;
  padding: 30px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, .3);

  ion-card-title {
    font-size: calc(1rem + 1.2vw);
    color: #ffffff;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .3);
  }
}


/*
  Colores de Fuentes de acuerdo a la paleta de colores
  Recomendación de uso para cuando la propiedad color de ionic no se aplica al componente
  ej: etiquetas i, p, h
*/
.font-color-primary {
  color: var(--ion-color-primary) !important;
}

.font-color-secondary {
  color: var(--ion-color-secondary) !important;
}

.font-color-tertiary {
  color: var(--ion-color-tertiary) !important;
}

.font-color-success {
  color: var(--ion-color-success) !important;
}

.font-color-warning {
  color: var(--ion-color-warning) !important;
}

.font-color-danger {
  color: var(--ion-color-danger) !important;
}

.font-color-light {
  color: var(--ion-color-light) !important;
}

.font-color-medium {
  color: var(--ion-color-medium) !important;
}

.font-color-dark {
  color: var(--ion-color-dark) !important;
}

.f-100 {
  font-weight: 100 !important;
}

.f-200 {
  font-weight: 200 !important;
}

.f-300 {
  font-weight: 300 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.f-800 {
  font-weight: 800 !important;
}

.f-900 {
  font-weight: 900 !important;
}

.f-s {
  font-size: .7rem !important;
}

.f-m {
  font-size: .9rem !important;

}

.f-xl {
  font-size: x-large !important;
}

.f-xxl {
  font-size: 2.1rem !important;
}

.f-note {
  font-size: small !important;
}

.element-transparent {
  --background: transparent;
  background: transparent;
  border: 0 !important;
}

.border-warning {
  border-left: 5px solid var(--ion-color-warning) !important;
  margin-bottom: 15px;
}

.border-bottom-secondary {
  border-bottom: 1px solid var(--ion-color-secondary) !important;
}

.border-secondary {
  //border: .1px solid var(--ion-color-secondary) !important;
  border-left: 4px solid var(--ion-color-secondary) !important;
  margin-bottom: 15px;
}

.border-dark {
  border-left: 5px solid var(--ion-color-dark) !important;
  margin-bottom: 15px;
}

.border-light {
  border-left: 5px solid var(--ion-color-light) !important;
  margin-bottom: 15px;
}

.border-danger {
  border-left: 5px solid var(--ion-color-danger) !important;
  margin-bottom: 15px;
}

.border-primary {
  //border: .1px solid var(--ion-color-primary-tint) !important;
  border-left: 4px solid var(--ion-color-primary) !important;
  margin-bottom: 15px;
}

.border-success {
  border-left: 5px solid rgba(var(--ion-color-success-rgb), 0.8) !important;
  margin-bottom: 15px;
}

.border-orange {
  border-left: 5px solid #e75410 !important;
  margin-bottom: 15px;
}

.border-rounded {
  border-radius: 8px;
}

.logo-ucc {
  width: 100%;
  display: block;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;

}

.logo-cmv {
  padding: 60px;
  max-width: 256px;
  width: 100%;
  height: 180px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card-image-md {
  width: 100%;
  display: block;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;


}


.profile-image {
  width: 120px;
  height: 120px;
  border: 5px solid transparent;
  box-shadow: 0 0 5px 1px rgba(103, 30, 117, 0.75);
  background-clip: padding-box;
}

.profile-image-sm {
  width: 70px;
  height: 70px;
  border: 2px solid transparent;
  box-shadow: 0 0 5px 1px rgba(103, 30, 117, 0.75);
  background-clip: padding-box;
}

.selected {
  border: .5px solid var(--ion-color-success) !important;
  box-shadow: 0 0 8px 3px rgba(25, 135, 84, 0.75) !important;
  background-clip: padding-box !important;
}

.logo-ucc-md {
  width: 100%;
  display: block;
  max-width: 250px;
  margin: 0 auto;
  padding: 10px;
}

.subheader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 28px;
}

.modal-height-lg {
  &::part(content) {
    border: 1px solid var(--ion-color-light);
    display: block;
    height: 80%;
    position: absolute;
    width: 50%;
  }
}

.ag-grid-cell-primary,
.ag-grid-cell-secondary,
.ag-grid-cell-tertiary,
.ag-grid-cell-success,
.ag-grid-cell-dark,
.ag-grid-cell-medium,
.ag-grid-cell-light,
.ag-grid-cell-warning,
.ag-grid-cell-danger {
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  transition: all .3s;
  cursor: pointer;
  padding: 0;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: 4px;
  width: 32px !important;
  height: 32px;


  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    filter: brightness(1.08);
  }

  ion-icon {
    padding-bottom: 3px;
    font-weight: 900;
  }
}

.ag-theme-alpine .ag-cell-focus {
  border: none !important;
}

.ag-theme-alpine .ag-cell-focus {
  outline: none;
}

.ag-cell-focus, .ag-cell {
  border: none !important;
}

.ag-grid-cell-primary {
  border: 1px solid var(--ion-color-primary) !important;
  color: var(--ion-color-primary);
}

.ag-grid-cell-secondary {
  border: 1px solid var(--ion-color-secondary) !important;
  color: var(--ion-color-secondary);;
}

.ag-grid-cell-success {
  border: 1px solid var(--ion-color-success) !important;
  color: var(--ion-color-success);
}

.ag-grid-cell-danger {
  border: 1px solid var(--ion-color-danger) !important;
  color: var(--ion-color-danger);
}

.ag-grid-cell-tertiary {
  border: 1px solid var(--ion-color-tertiary) !important;
  color: var(--ion-color-tertiary);
}

.ag-grid-cell-dark {
  border: 1px solid var(--ion-color-dark) !important;
  color: var(--ion-color-dark);
}

.ag-grid-cell-medium {
  border: 1px solid var(--ion-color-medium) !important;
  color: var(--ion-color-medium);
}

.ag-grid-cell-light {
  border: 1px solid var(--ion-color-light) !important;
  color: var(--ion-color-light);
}

.ag-grid-cell-warning {
  border: 1px solid var(--ion-color-warning) !important;
  color: var(--ion-color-warning);
}

.username-subheader {
  text-align: left;
  position: fixed;
  left: 28px;
  bottom: 0;
}

.amplify-button.amplify-button--primary {
  background-color: var(--ion-color-primary); /* Cambia esto al color deseado */
  color: white; /* Color del texto */
  border: none; /* Quita el borde si es necesario */
  padding: 10px 20px; /* Ajusta el padding */
  font-size: 16px; /* Ajusta el tamaño de la fuente */
  border-radius: 4px; /* Ajusta el radio de los bordes */
}

[data-amplify-router] {
  border-radius: 6px; /* Ajusta el radio del borde según tus necesidades */
  overflow: hidden; /* Asegura que los bordes no se recorten */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Agrega una sombra opcional para mejor apariencia */
  padding: 20px; /* Ajusta el padding según sea necesario */
  background-color: #f9f9f9; /* Ajusta el color de fondo según sea necesario */
  border: .5px solid var(--ion-color-primary) !important;
  margin-top: 32px;
}

.card-display-message-secondary {
  border: 1px solid #bee5eb !important;

  ion-item {
    --color: #0c5460;
    --background: #d1ecf1;
  }
}

.card-display-message-success {
  border: 1px solid #c3e6cb !important;

  ion-item {
    --color: #155724;
    --background: #d4edda;
  }
}

.card-display-message-warning {
  border: 1px solid #fff3cd !important;

  ion-item {
    --color: #856404;
    --background: #fff3cd;
  }
}

.card-display-message-danger {
  border: 1px solid #f5c6cb !important;

  ion-item {
    --color: #721c24;
    --background: #f8d7da;
  }
}


.ag-grid-icon {
  max-width: 50px;
  display: inline-block;
}

.ag-grid-icon-label {
  padding-left: 8px;
  font-size: .72rem;
  font-weight: 400
}


.datetime {
  border: none;
  padding: 0 16px
}

/*
Ventanas Modales para Ionic V6
Tamaños: [sm=20 , md= 40, lg=60, xl=90, xxl=100]  % de ancho
El alto debe ser dinámico aunque con un minimo de 10%
*/


.modal-xxl-float-left {
  &::part(content) {
    border: 1px solid var(--ion-color-light);
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 40%;
  }
}

.modal-w90 {
  --width: fit-content;
  --min-width: 60%;
  --height: 90%;
  --border-radius: 6px;
  --border: 1px solid var(--ion-color-light);
  //--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.custom-alert {
  --min-width: 400px;
}


.alert-red-text {
  --backdrop-opacity: 0.7;
  --min-width: 400px;

  .alert-title {
    font-weight: 500;
    color: var(--ion-color-secondary);

  }
}

//.alert-red-text .alert-wrapper {
//  background: #e5e5e5;
//}

